import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import headerImg from '../../images/front_asia.jpg';
import img1 from '../../images/asia_1.jpg';
import img2 from '../../images/asia_2.jpg';
import './work.css'

export default function AsiaButikk() {
  return (
        <div className="work">
            <div className="header">
                <LazyLoadImage width={'100%'} className="image" src={headerImg} alt="header"/>
                <div className="workInfo">
                    <div>
                        <h1>Hallingdal Asiabutikk</h1>
                    </div>
                    <div className="workDetails">
                        <div className="details">
                            <div className="detail">
                                <p>Kunde</p>
                                <p>Hallingdal Asiabutikk</p>
                            </div>
                            <div className="detail">
                                <p>Industri</p>
                                <p>Matvare</p>
                            </div>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <p>År</p>
                                <p>2021</p>
                            </div>
                            <div className="detail">
                                <p>Tjenester</p>
                                <p>Logo og fasadeutsmykning</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <p className="text">
                Hallingdal Asiabutikk på Gol har et eksotisk utvalg av importerte matvarer fra store deler av verden. 
                De hadde aldri hatt en visuell profil og så nå behovet for å bli mer synlig og tilgjengelig. 
                For å matche butikkens vareutvalg, ønsket de seg en frisk, fargerik og iøynefallende logo, 
                der navnet skulle være lett å lese. <br/><br/>
                En spesiell service butikken tilbyr er å dra til Oslo og håndplukke frukt og grønnsaker kunder har bestilt. Dette inspirerte oss til å bruke nettopp frukt
                i logodesignet.
            </p>

        <div className="images">
            <div className="divided">
                <img className="image" src={img1} alt="droym"/>
                <img className="image" src={img2} alt="droym"/>
            </div>
        </div>

        <div className="right">
            <p>
                Den nye logoen fikk en ren og stram font, som kan gi assosiasjoner til Østen. 
                    Ordet «Asiabutikk» er fremhevet med tykkere font for tydelighet og dynamikk. <br/><br/>
                    Logoikonet illustrerer en sukkerert, hvor ertene har fått friske farger for å gi den et mer sprudlende uttrykk og mer identitet. Dette gir også spillerom til å ta igjen farger fra logoen i f.eks. butikkinventar, fasadeutsmykning og lignende. Sukkererter kalles "Mange tout" på fransk, som betyr "spis alt" - dette kan jo sees som et lite ordspill på det store utvalget hos Hallingdal Asiabutikk.
            </p>
        </div>
       

    </div>
  );
}
