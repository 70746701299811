import React from "react";
import headerImg from '../../images/nhs_1.jpg';
import nhs1 from '../../images/nhs_header.jpg';
import nhs2 from '../../images/nhs_2.jpg';

export default function Nhs() {
  return (
        <div className="work">
            <div className="header">
                <img className="image" src={headerImg} alt="header" loading="lazy"/>
                <div className="workInfo">
                    <div>
                        <h1>Nesbyen Hytteservice</h1>
                    </div>
                    <div className="workDetails">
                        <div className="details">
                            <div className="detail">
                                <p>Kunde</p>
                                <p>Nesbyen hytteservice</p>
                            </div>
                            <div className="detail">
                                <p>Industri</p>
                                <p>Vaktmestertjeneste</p>
                            </div>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <p>År</p>
                                <p>2021</p>
                            </div>
                            <div className="detail">
                                <p>Tjenester</p>
                                <p>Logo Visittkortdesign</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <p className="text">
                Nesbyen Hytteservice har siden 2018 tilbydt et utvalg tjenester for hytteeiere
                i hele Hallingdal. De hadde imidlertid ikke skaffet seg en ordentlig logo,
                og følte nå tiden var inne for å få en tydeligere identitet. Kunde ønsket et
                moderne og rent uttrykk som kunne skille dem fra konkurrentene - gjerne
                med litt utypiske farger for segmentet, som trekker oppmerksomhet.
                <br/><br/>
                For å ikke låse uttrykket til kun èn tjeneste, valgte vi å bruke initialene som
                logoikon. Kunde kan dermed uten problemer legge til tjenester, eller bytte
                tjenesteområde fullstendig og likevel beholde ikonet som det er. Et unikt
                design ble dermed desto viktigere for gjenkjennelseseffekt og identitet.
            </p>

        <div className="images">
            <img className="image" src={nhs1} alt="droym"/>
            <div className="right">
            <p>
            Logoen ble satt i komplementærfargene blå og oransje for kraftig kontrast.
            H’en i ikonet kan gi assosiasjoner til en skigard – en tjeneste Nesbyen Hytteservice
            ofte utfører. Navnet er satt i en sans seriff-font i moderne stil, som
            komplementerer ikonet. Ordet «Hytteservice» er uthevet med fet font, da
            det er tjenestedelen kunde ønsket å få fokus på.
            </p>
        </div>
        </div>
        

        <div className="images">
            <img className="image" src={nhs2} alt="droym"/>
        </div>

        </div>
  );
}
