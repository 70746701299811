import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import headerImg from '../../images/front_visit_aal.jpg';
import img1 from '../../images/visitaal1.jpg';
import img2 from '../../images/visitaal2.jpg';
import img3 from '../../images/visitaal3.jpg';
import './work.css'

export default function VisitAal() {
  return (
        <div className="work">
            <div className="header">
                <LazyLoadImage width={'100%'} className="image" src={headerImg} alt="header"/>
                <div className="workInfo">
                    <div>
                        <h1>Visit Ål</h1>
                    </div>
                    <div className="workDetails">
                        <div className="details">
                            <div className="detail">
                                <p>Kunde</p>
                                <p>Visit Ål</p>
                            </div>
                            <div className="detail">
                                <p>Industri</p>
                                <p>Turistnæring</p>
                            </div>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <p>År</p>
                                <p>2021</p>
                            </div>
                            <div className="detail">
                                <p>Tjenester</p>
                                <p>Brosjyredesign</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <p className="text">
            Turistkontoret i Ål i Hallingdal ønsket et redesign av sin sommer-/vinterbrosjyre. De fikk en kvadratisk brosjyre med aktiviteter for både sommer og vinter, der hver årstid starter fra hver sin ende. Designet er stilrent og stramt, med fokous på store bildeflater.
            </p>

        <div className="images">
            <img className="image" src={img1} alt="droym"/>
            <div className="divided">
                <img className="image" src={img2} alt="droym"/>
                <img className="image" src={img3} alt="droym"/>
            </div>
            
        </div>
    </div>
  );
}
