import React from "react";
import headerImg from '../../images/ppt.jpg';

export default function Ppt() {
  return (
        <div className="work">
            <div className="header">
                <img className="image" src={headerImg} alt="header" loading="lazy"/>
                <div className="workInfo">
                    <div>
                        <h1>PPT og OT nedre Hallingdal IKS</h1>
                    </div>
                    <div className="workDetails">
                        <div className="details">
                            <div className="detail">
                                <p>Kunde</p>
                                <p>PPT og OT nedre Hallingdal IKS</p>
                            </div>
                            <div className="detail">
                                <p>Industri</p>
                                <p>Offentlig</p>
                            </div>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <p>År</p>
                                <p>2022</p>
                            </div>
                            <div className="detail">
                                <p>Tjenester</p>
                                <p>Logo</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <p className="text">
            PPT og OT nedre Hallingdal IKS (pedagogisk- psykologisk tjeneste og Oppfølgingstjenesten) er en interkommunal virksomhet med hovedkontor på Gol. 
            PPT arbeider for å sikre likeverdig opplæring for alle barn, unge og voksne med særlige behov, Oppfølgingstjenesten (OT) gir råd og veiledning 
            til ungdommer som står utenfor videregående opplæring. De ønsket seg en logo med mennesket og læring i fokus. <br/><br/> 
                <br></br>
                Det var her spesielt viktig at logoen ikke ekskluderer grupper, alder eller kjønn. Vi valgte derfor et kjønnsnøytralt menneskehode som logoikon. 
                Siden læring og personlig utvikling er positivt ladet, passet det med et hode som «bobler» av ideer, glede og selvsikkerhet. 
                Boblene er også med på å gjøre logoen mer dynamisk, slik at den lettere blir et blikkfang. Hodet har ansiktet vendt mot navnet, 
                noe som understreker samhandlingen og støtten mennesket får fra virksomheten. Grønnfargen er harmonisk og inkluderende, og 
                den mørke tonen underbygger seriøsiteten og profesjonaliteten hos PPT og OT.
            </p>
        </div>
  );
}
