import React from "react";
import headerImg from '../../images/droym.jpg';

export default function Droym() {
  return (
        <div className="work">
            <div className="header">
                <img className="image" src={headerImg} alt="header" loading="lazy"/>
                <div className="workInfo">
                    <div>
                        <h1>Dròym</h1>
                    </div>
                    <div className="workDetails">
                        <div className="details">
                            <div className="detail">
                                <p>Kunde</p>
                                <p>Dròym</p>
                            </div>
                            <div className="detail">
                                <p>Industri</p>
                                <p>Eiendomsutvikler</p>
                            </div>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <p>År</p>
                                <p>2022</p>
                            </div>
                            <div className="detail">
                                <p>Tjenester</p>
                                <p>Visuell identitet</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <p className="text">
            Dròym er et nyoppstartet firma som skal bygge og selge luksuriøse hytter i
            moderne laft over hele Norge. De ønsket seg en logo som gjenspeilet det
            eksklusive og luksuriøse hyttene deres representerer, og som ville differensiere
            dem fra lignende aktører. Resultatet ble en drømmeaktig, elegant og
            unik logo i dyp lilla og gull, som også underbygger det flotte navnet Dròym.
            </p>
        </div>
  );
}
