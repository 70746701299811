import './App.css';
import './pages/navbar.css';
import './pages/header.css';
import './pages/footer.css';
import {React} from "react";
import { Route, Routes, Link } from "react-router-dom";
import Home from "./pages/home";
import Droym from "./pages/work/droym";
import Fmv from "./pages/work/fmv";
import Nhs from "./pages/work/nhs";
import AsiaButikk from "./pages/work/asiabutikk";
import VisitAal from "./pages/work/visitAal";
import Synnfjell from "./pages/work/synnfjell";
import Sparebank1 from "./pages/work/sparebank1";
import GardenOppheim from "./pages/work/gardenoppheim";
import Okhagen from "./pages/work/okhagen";
import Ravind from "./pages/work/ravind";
import Ppt from "./pages/work/ppt";
import InnlandetFjellhytter from "./pages/work/innlandetfjellhytter";
import TakstkontoretValdres from "./pages/work/takstkontoretvaldres";
import SigveNordaker from "./pages/work/sigvenordaker";
import Work from "./pages/work";
import About from "./pages/about";
import ScrollToTop from "./pages/scrollToTop";
import { useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const routes = [
  { path: '/', name: 'Home', Component: Home },
  { path: '/arbeid/droym', name: 'Droym', Component: Droym },
  { path: '/arbeid/fmv', name: 'Fmv', Component: Fmv },
  { path: '/arbeid/nhs', name: 'Fmv', Component: Nhs },
  { path: '/arbeid/asiabutikk', name: 'Asiabutikk', Component: AsiaButikk },
  { path: '/arbeid/visitAal', name: 'VisitAal', Component: VisitAal },
  { path: '/arbeid/synnfjell', name: 'Synnfjell', Component: Synnfjell },
  { path: '/arbeid/sparebank1', name: 'Sparebank1', Component: Sparebank1 },
  { path: '/arbeid/gardenoppheim', name: 'Garden oppheim', Component: GardenOppheim },
  { path: '/arbeid/okhagen', name: 'Okhagen', Component: Okhagen },
  { path: '/arbeid/ravind', name: 'Ravind', Component: Ravind },
  { path: '/arbeid/ppt', name: 'PPT', Component: Ppt },
  { path: '/arbeid/innlandetfjellhytter', name: 'Innlandet fjellhytter', Component: InnlandetFjellhytter },
  { path: '/arbeid/takstkontoretvaldres', name: 'takstkontoret valdres', Component: TakstkontoretValdres },
  { path: '/arbeid/sigvenordaker', name: 'Anleggsgartner Sigve Nordaker', Component: SigveNordaker },
  { path: '/arbeid', name: 'Arbeid', Component: Work },
  { path: '/om', name: 'Om meg', Component: About }
]


export default function App() {

  const location = useLocation();
  
  return (

      <div className="container mouse-cursor-gradient-tracking" > 

        <div className="navbar">
          <ul>
            <li><Link className="link__text__effect"  to="/">ELIN SPILDREJORDE TYSTAD</Link></li>
            <div className='navbar__right'>
              <li className='work'><Link className="link__text__effect"  to="/arbeid">Arbeid</Link></li>
              <li><Link className="link__text__effect"  to="/om">Om meg</Link></li>
            </div>
          </ul>
        </div>

        <ScrollToTop>
          <TransitionGroup component={null}>
            <CSSTransition key={location.key} classNames="fade" timeout={300}>
              <Routes>
                {routes.map(({ path, Component }, index) => (
                  <Route key={path} exact path={path} element={
                    <div className="fade">
                      <Component />
                    </div>
                  } />
                  ))}
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        </ScrollToTop>

        <div className="footer">
          <div className="mail">
            <p>Kontakt meg gjerne</p>
            <a className="link__text__effect" href= "mailto:etystad@gmail.com">etystad@gmail.com</a>
          </div>
          <div className="social">
            <a className="link__text__effect"  target="_blank" href="https://no.linkedin.com/in/elin-spildrejorde-tystad-1599a96a">LinkedIn</a>
            <a className="link__text__effect" target="_blank" href="https://www.instagram.com/elinspildrejorde/">Instagram</a>
          </div>
        </div>

      </div>
  );
}

