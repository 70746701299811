import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import headerImg from '../../images/synnfjell.jpg';
import img1 from '../../images/synnfjell1.jpg';
import img2 from '../../images/synnfjell2.jpg';
import img3 from '../../images/synnfjell3.jpg';
import img4 from '../../images/synnfjell4.jpg';
import './work.css'

export default function Synnfjell() {
  return (
        <div className="work">
            <div className="header">
                <LazyLoadImage width={'100%'} className="image" src={headerImg} alt="header"/>
                <div className="workInfo">
                    <div>
                        <h1>Visit Synnfjell</h1>
                    </div>
                    <div className="workDetails">
                        <div className="details">
                            <div className="detail">
                                <p>Kunde</p>
                                <p>Land Utvikling SA</p>
                            </div>
                            <div className="detail">
                                <p>Industri</p>
                                <p>Destinasjonsutvikling</p>
                            </div>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <p>År</p>
                                <p>2021</p>
                            </div>
                            <div className="detail">
                                <p>Tjenester</p>
                                <p>Logo</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <p className="text">
                

            Det vakre fjellområdet Synnfjell i Nordre Land, Valdres trengte en ny logo for promotering og synlighet. 
            Kundes eneste kriterie var at logoikonet skulle formidle "fjell", og at det gjerne kunne være moderne i uttrykket. <br/><br/>

                Designet ble en ren og minimalistisk form, hvor man lett kjenner igjen fjellformen. Linjene kan representere de flotte turstiene i området eller oppmerkede løyper på et kart. Ved å gi logoen et tidløst og stramt uttrykk tar Synnfjell sin plass i markedet, og får en tydelig og unik profil.
                <br/><br/>
               
            </p>

        <div className="images">
            <img className="image" src={img1} alt="droym"/>
            <div className="divided">
                <img className="image" src={img2} alt="droym"/>
                <img className="image" src={img3} alt="droym"/>
            </div>
            
        </div>

        <div className="images">
            <img className="image" src={img4} alt="droym"/>
        </div>
        

        </div>
  );
}
