import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import headerImg from '../../images/fmv_header.jpg';
import fmv1 from '../../images/fmv_1.jpg';
import fmv2 from '../../images/fmv_2.jpg';
import fmv3 from '../../images/fmv_3.jpg';
import fmv4 from '../../images/fmv_4.jpg';
import fmv5 from '../../images/fmv_5.jpg';
import './work.css'

export default function Fmv() {
  return (
        <div className="work">
            <div className="header">
                <LazyLoadImage width={'100%'} className="image" src={fmv1} alt="header"/>
                <div className="workInfo">
                    <div>
                        <h1>Folkemusikkveka</h1>
                    </div>
                    <div className="workDetails">
                        <div className="details">
                            <div className="detail">
                                <p>Kunde</p>
                                <p>Folkemusikkveka</p>
                            </div>
                            <div className="detail">
                                <p>Industri</p>
                                <p>Musikkfestival</p>
                            </div>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <p>År</p>
                                <p>2021</p>
                            </div>
                            <div className="detail">
                                <p>Tjenester</p>
                                <p>Visuell identitet Nettsidedesign</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <p className="text">
            Folkemusikkveka avholdes årlig på Ål i Hallingdal. Etter noen år med et sprikende visuelt uttrykk, ønsket de seg en ny profil som kunne gi dem en tydelig og gjenkjennbar identitet. De så for seg et design som ville forene det tradisjonelle aspektet fra folkemusikk/dans med det moderne og fremtidsrettede.

                <br/><br/>
                Det nye logodesignet viser forkortelsen av festivalnavnet «FMV», der F’ens øverste «arm» er strukket ut så den ligner en stav med hatt – et kjent tilbehør fra folkedansen. Bokstavene er skråstilte for å understreke dans, bevegelse, energi og glede. Sirkelen bak bokstavene danner en fin ramme, og kan assosieres med solen eller en «spotlight». Festivalen holdes i slutten av mai, så den kjølige og friske fargepalletten representerer den friske vårluften i Hallingdal.
            </p>

            <div className="images">
                <img className="image" src={fmv3} alt="droym"/>
                <div className="divided">
                    <img className="image" src={fmv2} alt="droym"/>
                    <img className="image" src={headerImg} alt="droym"/>
                </div>
            </div>

            <div className="right">
                <p>Festivalens opprinnelige nettside var tungvind å oppdatere og kronglete å navigere på for bruker. Hovedfokuset for det nye nettside-designet ble derfor en tydelig og brukervennlig struktur. Underveis skal bruker få en hyggelig visuell opplevelse, med glade energiske farger og store og stemningsfulle bilder som viser både ny og gammel moro. Nettsiden har fått sin nye drakt fra festivalens nye profil, så det hele er tydelig bundet sammen.
                </p>
                <a target="_blank" rel="noreferrer" href="https://www.folkemusikkveka.no/">folkemusikkveka.no</a>
            </div>

            <div className="images">
                <img className="image" src={fmv4} alt="droym"/>
                <img className="image" src={fmv5} alt="droym"/>
            </div>
            
        </div>
  );
}
