import React from "react";
import {Link } from "react-router-dom";
import frontFmv from '../images/fmv_header.jpg'; //'../images/fmv_header.jpg';
import frontDroym from '../images/droym.jpg';
import frontAsia from '../images/front_asia.jpg';
import frontVisitAal from '../images/front_visit_aal.jpg';
import frontNhs from '../images/nhs_1.jpg';
import synnfjell from '../images/synnfjell.jpg';
import sparebank1 from '../images/sparebank1.jpg';
import gardenoppheim from '../images/gardenoppheim.jpg';
import okhagen from '../images/okhagen.jpg';
import ravind from '../images/ravind2.jpg';
import ppt from '../images/ppt.jpg';
import innlandetfjellhytter from '../images/innlandetfjellhytter1.jpg';
import takstkontoretValdres from '../images/takstkontoretvaldres.jpg';
import sigvenordaker from '../images/sigvenordaker.jpg';

import './work.css';
import { Fade } from "react-awesome-reveal";
import { keyframes } from "@emotion/react"



export default function Work() {

  var duration = 2000;
  var triggerOnce = true;
  var direction = "up";

  const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate(0px,150px);
  }

  to {
    opacity: 1;
    transform: translate(0px,0px);
  }
`;
  

  return (
    <div className="works">
      <div className="work__mosaic">

        <Fade  className="work__mosaic__img__wrap" keyframes={customAnimation}  triggerOnce={triggerOnce} duration={duration}>
            <Link className="work__mosaic__link" to="/arbeid/droym">
              <img src={frontDroym} className="work__mosaic__img__img" alt="frontDroym"/>
              <h3 className="work__mosaic__title">DRÒYM</h3>
              <p className="work__mosaic__subtext">logo</p>
            </Link>
            <Link className="work__mosaic__link" to="/arbeid/fmv">
              <img src={frontFmv} className="work__mosaic__img__img" alt="frontFmv"/>
              <h3 className="work__mosaic__title">FOLKEMUSIKKVEKA</h3>
             <p className="work__mosaic__subtext">visuell profil</p>
            </Link>
            <Link className="work__mosaic__link" to="/arbeid/asiabutikk">
              <img src={frontAsia} className="work__mosaic__img__img" alt="frontFmv"/>
              <h3 className="work__mosaic__title">HALLINGDAL ASIABUTIKK</h3>
              <p className="work__mosaic__subtext">visuell profil</p>
            </Link>
            <Link className="work__mosaic__link" to="/arbeid/visitAal">
              <img src={frontVisitAal} className="work__mosaic__img__img" alt="frontFmv"/>
              <h3 className="work__mosaic__title">VISIT ÅL</h3>
              <p className="work__mosaic__subtext">brosjyredesign</p>
            </Link>
            <Link className="work__mosaic__link" to="/arbeid/synnfjell">
              <img src={synnfjell} className="work__mosaic__img__img" alt="frontFmv"/>
              <h3 className="work__mosaic__title">SYNNFJELL</h3>
              <p className="work__mosaic__subtext">logo</p>
            </Link>
            <Link className="work__mosaic__link" to="/arbeid/nhs">
              <img src={frontNhs} className="work__mosaic__img__img" alt="frontNhs"/>
              <h3 className="work__mosaic__title">NESBYEN HYTTESERVICE</h3>
              <p className="work__mosaic__subtext">logo</p>
            </Link>
            <Link className="work__mosaic__link" to="/arbeid/sparebank1">
              <img src={sparebank1} className="work__mosaic__img__img" alt="frontFmv"/>
              <h3 className="work__mosaic__title">SPAREBANK1 HALLINGDAL VALDRES</h3>
              <p className="work__mosaic__subtext">annonse</p>
            </Link>
            <Link className="work__mosaic__link" to="/arbeid/gardenoppheim">
              <img src={gardenoppheim} className="work__mosaic__img__img" alt="frontNhs"/>
              <h3 className="work__mosaic__title">GARDEN OPPHEIM</h3>
              <p className="work__mosaic__subtext">emballasjedesign</p>
            </Link>
            <Link className="work__mosaic__link" to="/arbeid/okhagen">
              <img src={okhagen} className="work__mosaic__img__img" alt="okhagen"/>
              <h3 className="work__mosaic__title">OK HAGEN</h3>
              <p className="work__mosaic__subtext">logo</p>
            </Link>
            <Link className="work__mosaic__link" to="/arbeid/ravind">
              <img src={ravind} className="work__mosaic__img__img" alt="ravind"/>
              <h3 className="work__mosaic__title">RAVIND</h3>
              <p className="work__mosaic__subtext">logo</p>
            </Link>
            <Link className="work__mosaic__link" to="/arbeid/ppt">
              <img src={ppt} className="work__mosaic__img__img" alt="ppt"/>
              <h3 className="work__mosaic__title">PPT OG OT NEDRE HALLINGDAL IKS</h3>
              <p className="work__mosaic__subtext">logo</p>
            </Link>
            <Link className="work__mosaic__link" to="/arbeid/innlandetfjellhytter">
              <img src={innlandetfjellhytter} className="work__mosaic__img__img" alt="ppt"/>
              <h3 className="work__mosaic__title">INNLANDET FJELLHYTTER</h3>
              <p className="work__mosaic__subtext">logo</p>
            </Link>
            <Link className="work__mosaic__link" to="/arbeid/takstkontoretvaldres">
              <img src={takstkontoretValdres} className="work__mosaic__img__img" alt="ppt"/>
              <h3 className="work__mosaic__title">TAKSTKONTORET VALDRES</h3>
              <p className="work__mosaic__subtext">logo</p>
            </Link>
            <Link className="work__mosaic__link" to="/arbeid/sigvenordaker">
              <img src={sigvenordaker} className="work__mosaic__img__img" alt="Sigve Nordaker"/>
              <h3 className="work__mosaic__title">ANLEGGSGARTNER SIGVE NORDAKER</h3>
              <p className="work__mosaic__subtext">logo</p>
            </Link>

            
        </Fade>
      </div>
    </div>
  );
}
