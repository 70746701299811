import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import headerImg from '../../images/ravind2.jpg';
import img1 from '../../images/ravind1.jpg';
import img2 from '../../images/ravind.jpg';
import img3 from '../../images/ravind3.jpg';
import './work.css'

export default function Ravind() {
  return (
        <div className="work">
            <div className="header">
                <LazyLoadImage width={'100%'} className="image" src={headerImg} alt="header"/>
                <div className="workInfo">
                    <div>
                        <h1>Ravind</h1>
                    </div>
                    <div className="workDetails">
                        <div className="details">
                            <div className="detail">
                                <p>Kunde</p>
                                <p>Ravind</p>
                            </div>
                            <div className="detail">
                                <p>Industri</p>
                                <p>Regnskapstjenester</p>
                            </div>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <p>År</p>
                                <p>2021</p>
                            </div>
                            <div className="detail">
                                <p>Tjenester</p>
                                <p>Logo</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <p className="text">
            Ravind er et autorisert regnskapsførerselskap som tilbyr regnskapstjenester, rådgivning og management for hire.
            De ønsket å fremstå som et seriøst, profesjonelt og trygt firma , der kundeveiledning står i fokus.

                
            </p>

        <div className="images">
            <div className="dividedthree">
                <img className="image" src={img1} alt="ravind"/>
                <img className="image" src={img2} alt="ravind"/>
                <img className="image" src={img3} alt="ravind"/>
            </div>
        </div>

        <div className="right">
            <p>
                Rammen rundt R’en i ikonet skal uttrykke «trygghet»/ «trygge rammer». 
                Den får også et emblem-uttrykk som gjør at den kan stå sterkt alene. 
                <br/><br/>
                R’en er spesialdesignet, og gir dermed Ravind sin egen, tydelige identitet. Fonten er en elegant og seriøs seriff-font, men oppleves også som betryggende og «koselig» med sine runde former.
                Logoen har en fin balanse mellom seriøs og tilgjengelig.
            </p>
        </div>

        

        </div>
  );
}
