import {React} from "react";
import './home.css'

export default function Home() {
  
  return (
    <div className="home">
      <div className="header">
        <div className="sameLine">
          <h1>GRAFISK </h1><h1 className="bold">DESIGNER</h1>
        </div>
        <h1>MED FORKJÆRLIGHET</h1>
        <div className="sameLine">
          <h1>FOR</h1><h1 className="bold">VISUELL</h1><h1 className="last">PROFILERING</h1><span className="punktum"></span>
        </div>
        <p className="cursive">MEN JEG JOBBER GJERNE MED ANDRE TING OGSÅ!</p>
        <div className="right">
          <p>
          Min bachelor i visuell kommunikasjon har gitt meg kunnskap om alt fra kunsthistorie, typografi, pakningsdesign og markedsføring 
          til redaksjonell og strategisk design, visuell profilering og webdesign. Videre har den åpnet dører til avisbransjen, reklamebyrå og sterke merkevarebedrifter
        </p>
        </div>
      </div>
    </div>
  );
}
