import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import headerImg from '../../images/innlandetfjellhytter1.jpg';
import img1 from '../../images/innlandetfjellhytter.jpg';
import img2 from '../../images/innlandetfjellhytter2.jpg';
import img3 from '../../images/innlandetfjellhytter3.jpg';
import img4 from '../../images/innlandetfjellhytter4.jpg';
import './work.css'

export default function InnlandetFjellhytter() {
  return (
        <div className="work">
            <div className="header">
                <LazyLoadImage width={'100%'} className="image" src={headerImg} alt="header"/>
                <div className="workInfo">
                    <div>
                        <h1>Innlandet fjellhytter</h1>
                        <h3>Logo</h3>
                    </div>
                    <div className="workDetails">
                        <div className="details">
                            <div className="detail">
                                <p>Kunde</p>
                                <p>Innlandet fjellhytter</p>
                            </div>
                            <div className="detail">
                                <p>Industri</p>
                                <p>Hytteutvikling</p>
                            </div>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <p>År</p>
                                <p>2021</p>
                            </div>
                            <div className="detail">
                                <p>Tjenester</p>
                                <p>Logo</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <p className="text">
                Innlandet Fjellhytter jobber innen hytteutvikling, og var klare i sitt ønske om en moderne og stilfull logo.
            </p>

        <div className="images">
            <img className="image" src={img1} alt="fjellhytter"/>
            <div className="divided">
                <img className="image" src={img2} alt="fjellhytter"/>
                <img className="image" src={img3} alt="fjellhytter"/>
            </div>
            
        </div>

        <div className="right">
            <p>
                For å skape identitet valgte vi å bruke initialene som logoikon. Ved å skråstille I’en og F’en fikk vi bokstavene til å ligne fjell. 
                Prikken over i’en ble dermed en sol som enten står opp eller går ned over fjellkjeden.
                Det noe stramme uttrykket i ikonet mykes opp av den runde og trivelige fonten i navnet.
              
            </p>
        </div>

        <div className="images">
            <img className="image" src={img4} alt="fjellhytter"/>
        </div>
    
    </div>
  );
}
