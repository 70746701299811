import React from "react";
import headerImg from '../../images/okhagen.jpg';
import img1 from '../../images/okhagen1.jpg';

export default function Okhagen() {
  return (
        <div className="work">
            <div className="header">
                <img className="image" src={headerImg} alt="header" loading="lazy"/>
                <div className="workInfo">
                    <div>
                        <h1>Ok hagen</h1>
                    </div>
                    <div className="workDetails">
                        <div className="details">
                            <div className="detail">
                                <p>Kunde</p>
                                <p>Ok hagen</p>
                            </div>
                            <div className="detail">
                                <p>Industri</p>
                                <p>Varehandel</p>
                            </div>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <p>År</p>
                                <p>2021</p>
                            </div>
                            <div className="detail">
                                <p>Tjenester</p>
                                <p>Logo</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <p className="text">
                OK Hagen Valdres er en optiker, ur- og gullsmedbutikk, som også selger interiørvarer. 
                I forbindelse med oppussing av sitt butikklokale, ønsket de seg en ny logo. 
                De så for seg firmanavnet med høye, slanke bokstaver, med gull som hovedfarge. 
                Dette ville stå i smakfull kontrast til lokalet, som nå skulle males svart.
                <br/><br/>
                Logoen ble til slutt enkel og elegant, med butikknavnet i hovedfokus. 
                Siden bedriften har eksistert siden 1912, var det viktig og få med årstallet i logoen for 
                tyngde og seriøsitet. Lokoikonet kan forestille en diamantring eller et øye, og assosierer til kvalitet og stil. 
            </p>

        <div className="images">
            <img className="image" src={img1} alt="okhagen"/>
        </div>

        </div>
  );
}
