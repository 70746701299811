import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import headerImg from '../../images/sigvenordaker.jpg';
import img1 from '../../images/sigvenordaker1.jpg';
import img2 from '../../images/sigvenordaker2.jpg';
import './work.css'

export default function SigveNordaker() {
  return (
        <div className="work">
            <div className="header">
                <LazyLoadImage width={'100%'} className="image" src={headerImg} alt="header"/>
                <div className="workInfo">
                    <div>
                        <h1>Anleggsgartner Sigve Nordåker</h1>
                    </div>
                    <div className="workDetails">
                        <div className="details">
                            <div className="detail">
                                <p>Kunde</p>
                                <p>Anleggsgartner Sigve Nordåker</p>
                            </div>
                            <div className="detail">
                                <p>Industri</p>
                                <p>Anleggsgartner</p>
                            </div>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <p>År</p>
                                <p>2021</p>
                            </div>
                            <div className="detail">
                                <p>Tjenester</p>
                                <p>Logo</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <p className="text">
            Sigve Nordåker trengte logo til sitt nystartede firma. Som anleggsgartner jobber han mest med steinglegging og graving, 
            og ønsket et ikon som formidlet dette. For å skille seg ut i mengden, så han for seg logoen i en frisk og spennede farge.
                <br/><br/>
                Ikonet er fremstilt enkelt og stilrent, og forestiller steinheller lagt i en dekorativ formasjon. Vi havnet på en 
                livlig guloransje farge, som synes godt og har en positiv klang. Fontvektene skaper en dynamisk kontrast i navnet
            </p>

        <div className="images">
            <div className="divided">
                <img className="image" src={img1} alt="ravind"/>
                <img className="image" src={img2} alt="ravind"/>
            </div>
        </div>
        </div>
  );
}
