import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import headerImg from '../../images/gardenoppheim.jpg';
import img1 from '../../images/gardenoppheim1.jpg';
import img2 from '../../images/gardenoppheim2.jpg';
import img3 from '../../images/gardenoppheim3.jpg';
import './work.css'

export default function GardenOppheim() {
  return (
        <div className="work">
            <div className="header">
                <LazyLoadImage width={'100%'} className="image" src={headerImg} alt="header"/>
                <div className="workInfo">
                    <div>
                        <h1>Garden Oppheim</h1>
                    </div>
                    <div className="workDetails">
                        <div className="details">
                            <div className="detail">
                                <p>Kunde</p>
                                <p>Garden oppheim</p>
                            </div>
                            <div className="detail">
                                <p>Industri</p>
                                <p>Landbruk</p>
                            </div>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <p>År</p>
                                <p>2021</p>
                            </div>
                            <div className="detail">
                                <p>Tjenester</p>
                                <p>Emballasjedesign</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <p className="text">
            Garden Oppheim spesialiserer seg på videreforedling av spekemat – spesielt ulike produkter av pinnekjøtt – gjennom tradisjonelle metoder på 
            familiegården på Ål. De ønsket nytt emballasjedesign som skulle gjenspeile varenes høye kvalitet, og skille Garden Oppheim fra lignende aktører.
                
                </p>

        <div className="images">
            <div className="dividedthree">
                <img className="image" src={img1} alt="droym"/>
                <img className="image" src={img2} alt="droym"/>
                <img className="image" src={img3} alt="droym"/>
            </div>
        </div>

        <div className="right">
            <p>
            Brunfargen fra Garden Oppheims logo ble brukt som hovedfarge på emballasjene, for å understreke deres identitet. 
            Fotografiet som går igjen på alle emballasjer viser sauenes beiteområde i Raggsteindalen. <br/><br/>
            For å skille produktene fra hverandre, har alle kjøtt-typene fått hver sin fargestripe under bildet. 
            Egne farger øverst skiller også mellom røkt (brun) og urøkt kjøtt (hvit). 
            På denne måten skal det bli litt lettere for å kjapt spotte sin favoritt i hyllen. <br/><br/>
            På baksiden kan man lese om familien som driver Garden Oppheim, deres kjærligheten til dyrene og om prosessen i kjøttforedlingen på gården - en fin måte og vise at produktene er kortreiste og trygge.
            </p>
        </div>

        

        </div>
  );
}
