import {React} from "react";
import elin from '../images/elin.jpg';
import { Fade } from "react-awesome-reveal";
import { keyframes } from "@emotion/react"
import './about.css';


export default function About() {

    var duration = 2000;
    var triggerOnce = true;
    var direction = "up";
  
    const customAnimation = keyframes`
    from {
      opacity: 0;
      transform: translate(0px,150px);
    }
  
    to {
      opacity: 1;
      transform: translate(0px,0px);
    }
  `;

  return (
    <div className="about">
        
        <Fade keyframes={customAnimation}  triggerOnce={triggerOnce} duration={duration}>
            <div className="text__image__top">
            <p className="big__space__text">
                Jeg var hun som satt på jenterommet og tegnet mens de andre spilte håndball eller i korps.
            </p>
            <div className="image__text__block">
                <img className="image__text__block__image" src={elin} alt="Bilde av meg"></img>
                <p className="image__text__block__text">
                    Vel, jeg var såvidt innom å klimpra litt på en bass i et såkalt “jenteband”, men det ble visst ikke noen suksess, så jeg trakk meg tilbake til blyanten. Det ballet på seg til “Tegning, form og farge”-linja på videregående,
                    og videre til kunsthistorie, og en bachelor i visuell kommunikasjon. Blyanten er i dag byttet ut med skjerm og mus, og jenterommet med et småbruk i Oppheim i Ål, Hallingdal. Men fokuset og lidenskapen er den samme.
                </p>
            </div>
            </div>
        </Fade>
        <div className="experience__block">
        <Fade keyframes={customAnimation}  triggerOnce={triggerOnce} duration={duration}>
            <h2 className="about__h2">ERFARINGEN</h2>
            <p className="experience__block__ingress">
                Så ble jeg altså grafisk designer. Ikke visste jeg da hvor mye dette yrket rommer - at det har så mange greiner.
                Jeg har heldigvis fått klatre på forskjellig sådan, noe som har gitt meg variert erfaring:
            </p>
            </Fade>
            <Fade className="experience__horizontal__block" keyframes={customAnimation} triggerOnce={triggerOnce} duration={duration}>

                <div className="experience__horizontal__block__content">
                <p className="experience__block__dropdown_heading">2017–2018 // Mediegrafiker i <a target="_blank" href="https://www.hallingdolen.no">Hallingdølen <span  className="experience__block__dropdown_heading__punktum"></span></a></p>
                    
                    <p className="experience__block__dropdown_content_info">
                    
                    <p className="experience__block__dropdown_content_info"></p>
                        Politisk uavhengig lokalavis i Hallingdal. Kåret til årets lokalavis i 2005, 2008,
                        2012 og 2014. I 2014 og 2021 ble Hallingdølen også kåret til årets europeiske
                        lokalavis i European newspaper Award.</p>
                    
                    <p className="experience__block__dropdown_content_my_role">
                        Mediegrafiker på grafisk avdeling. Ansvar for annonseproduksjon og for
                        produksjon og utvikling av diverse bilag (magasiner, brosjyrer, flyers mm.). Designet og satte også opp nettsider i Wordpress.
                    </p>
                </div>
                <div className="experience__horizontal__block__content">
                <p className="experience__block__dropdown_heading">2019–2022 // Fagansvarlig designer i reklamebyrået <a target="_blank" href="https://www.tala.no">TALA <span  className="experience__block__dropdown_heading__punktum"></span></a></p>
                    <p className="experience__block__dropdown_content_info">
                    
                     TALA hjelper bedrifter med markedsføring - synlighet og presentasjon. 
                    </p>
                    <p className="experience__block__dropdown_content_my_role">
                    Fagansvarlig designer med hovedansvar for avd. Hallingdal. Prosjektleder med oppfølging av kunder fra start til slutt. 
                    Variert hverdag med visuell profilering, strategisk design, visuell identitet, design av nettsider, emballasjedesign, 
                    skiltdesign, bildekor, brosjyredesign, kampanjemateriell, salgsprospekt, tekstforfatter, visittkort, messemateriell. 
                    </p>
                </div>
                <div className="experience__horizontal__block__content">
                <p className="experience__block__dropdown_heading">2022- // Inhouse grafisk designer for <a  target="_blank" href="https://www.espegard.no">Espegard<span  className="experience__block__dropdown_heading__punktum"></span></a></p>
                    <p className="experience__block__dropdown_content_info">
                     Espegard legger til rette for mer tid ute med produkter som bålpanne, utepeis,
                        vedsekker og tretjære. 35 år med fokus på kvalitet har gitt god erfaring.</p>
                    <p className="experience__block__dropdown_content_my_role">
                    Inhouse grafisk designer. Ansvarlig for at bedriften følger sin visuelle profil i all visuell eksponering, og er hovedkontakt
                    for eksterne designbyråer. Produksjon og utvikling av emballasjer og salgsmateriell som brosjyrer, DM's, annonser mm.
                    </p>
                </div>
            </Fade>
            
        </div>

        <Fade keyframes={customAnimation}  triggerOnce={triggerOnce} duration={duration}>
        <div className="quality__block">
            <h2 className="about__h2">KVALITETEN</h2>
            <p>
                Disse erfaringene har formet meg til en selvstendig og allsidig designer, som alltid streber etter å levere kvalitet. 
                Det ligger alltid en strategisk tankegang i bunnen av den kreative prosessen, slik
                at budskapet blir spisset i riktig retning.
            </p>
        </div>
        </Fade>

        <Fade keyframes={customAnimation}  triggerOnce={triggerOnce} duration={duration}>
        <div className="passion__block">
            <h2 className="about__h2">LIDENSKAPEN</h2>
            <p>
            Jeg har blitt spesielt glad i utvikling av visuelle profiler.
            Vel, det er vel egentlig et “elsk-/hat”-forhold om jeg skal
            være helt ærlig. Det er i dette arbeidet jeg lettest
            kommer inn i den kreative “bobla” - hvor jeg kjenner
            hjernen gnistrer. Men det er også når man vrir og vender
            på hjernen over lang tid man blir frustrert, redd, lei seg,
            sint og “ dausliten” Men det er verdt det. Når det “sitter”.
            </p>
        </div>
        </Fade>


    </div>
  );
}
