import React from "react";
import headerImg from '../../images/sparebank1.jpg';

export default function Sparebank1() {
  return (
        <div className="work">
            <div className="header">
                <img className="image" src={headerImg} alt="header" loading="lazy"/>
                <div className="workInfo">
                    <div>
                        <h1>Sparebank1 Hallingdal Valdres</h1>
                    </div>
                    <div className="workDetails">
                        <div className="details">
                            <div className="detail">
                                <p>Kunde</p>
                                <p>Sparebank1 Hallingdal Valdres</p>
                            </div>
                            <div className="detail">
                                <p>Industri</p>
                                <p>Bank</p>
                            </div>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <p>År</p>
                                <p>2022</p>
                            </div>
                            <div className="detail">
                                <p>Tjenester</p>
                                <p>Annonse</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <p className="text">
            Sparebank1 Hallingdal Valdres ønsket et nytt design av sin annonse angående sitt samfunnsengasjement. Jeg holdt meg innenfor rammene av deres visuelle profil, og lekte med flere av fargene og profilens organiske organiske former, slik at annonsen skulle bli et blikkfang med et mer dynamisk uttrykk.
            </p>
        </div>
  );
}
