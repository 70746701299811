import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import headerImg from '../../images/takstkontoretvaldres.jpg';
import img1 from '../../images/takstkontoretvaldres1.jpg';
import img2 from '../../images/takstkontoretvaldres2.jpg';
import './work.css'

export default function TakstkontoretValdres() {
  return (
        <div className="work">
            <div className="header">
                <LazyLoadImage width={'100%'} className="image" src={headerImg} alt="header"/>
                <div className="workInfo">
                    <div>
                        <h1>Takstkontoret Valdres</h1>
                    </div>
                    <div className="workDetails">
                        <div className="details">
                            <div className="detail">
                                <p>Kunde</p>
                                <p>Takstkontoret Valdres</p>
                            </div>
                            <div className="detail">
                                <p>Industri</p>
                                <p>Takst</p>
                            </div>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <p>År</p>
                                <p>2021</p>
                            </div>
                            <div className="detail">
                                <p>Tjenester</p>
                                <p>Logo</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <p className="text">
                Takstkontoret Valdres trengte ny logo i forbindelse med fusjonering av to firmaer. Ønsket var et moderne og stramt uttrykk som gir assosiasjoner til seriøsitet og kvalitet, gjerne med et innslag av farge.
            </p>

        <div className="images">
            <div className="divided">
                <img className="image" src={img1} alt="fjellhytter"/>
                <img className="image" src={img2} alt="fjellhytter"/>
            </div>
            
        </div>

        <div className="right">
            <p>
            T’en i navnet ble trukket ut og designet slik at den kan minne om hus-/vindusformer. Den sirkulære rammen symbolierer hvordan man blir ivaretatt av fagpersoner i Takstkontoret Valdres. samtidig som det gir et emblem-uttrykk så ikonet lett kan fristilles.  Rammen fikk en mørkeblå farge, som gjentas på strekene i navnet. Blåfargen assosieres ofte med seriøsitet, stabilitet og tillit. Ordet «takstkontoret» er blåst opp slik at det tydelig kommuniseres hva firmaet jobber med.
            </p>
        </div>

    
    </div>
  );
}
